export async function DiagramAnalytics() {
    //GA for Video
    try {
        var checkYoutubeIframe = false;

        //Verify all iFrames from the page
        $("iframe").each(function () {

            //Check if you a YT iFrame
            if ($(this).attr('src').indexOf("youtube.com/embed") >= 0) {
                checkYoutubeIframe = true;

                //Add Enable JS API parameter
                if ($(this).attr('src').indexOf("enablejsapi=1") == -1) {
                    if ($(this).attr('src').indexOf("?") >= 0) {
                        var src = $(this).attr('src') + "&enablejsapi=1&rel=0"
                        $(this).attr('src', src)
                    }
                    else {
                        var src = $(this).attr('src') + "?enablejsapi=1&rel=0"
                        $(this).attr('src', src)
                    }
                }
            }
        })

        //Call logic if there is an iFrame on the page
        if (checkYoutubeIframe) {

            //Include YT API
            var tag = document.createElement('script');
            tag.src = "//www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            WSOL.hasYTembed = true;

            WSOL.onPlayerReady = function (event) {
            }
            //Tracker

            WSOL.onPlayerStateChange = function (event) {
                var url = event.target.getVideoUrl();
                // track when user clicks to Play
                if (event.data == YT.PlayerState.PLAYING) {
                    customGAEvent('Video', 'Play', url);
                }
                // track when user clicks to Pause
                if (event.data == YT.PlayerState.PAUSED) {
                    customGAEvent('Video', 'Pause', url);
                }
                // track when user clicks to Pause
                if (event.data == YT.PlayerState.ENDED) {
                    customGAEvent('Video', 'Watched to End', url);
                }
            }
        }
    } catch (e) {
    }

    $(window).click(function (evt) {
        var $elm = $(evt.target).closest('a');
        if ($elm.attr('data-ga-category') != undefined && $elm.attr('data-ga-event') != undefined) {
            customGAEvent($elm.attr('data-ga-category'), $elm.attr('data-ga-event'));
        }
    });
}