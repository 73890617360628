﻿export function DiagramHasJS() {
    var $html = document.getElementsByTagName('html')[0];

    if ($html.classList) {
        $html.classList.remove('no-js');
    } else {
        $html.className = $html.className.replace(new RegExp('(^|\\b) no-js(\\b|$)', 'gi'), ' ');
    }

    if ($html.classList) {
        $html.classList.add('js');
    } else {
        $html.className += ' js';
    }
};