import '@/modules/Diagram.jQueryGlobal.js';
import { DiagramHasJS } from '@/modules/Diagram.hasJS.js';
import { DiagramAnalytics } from '@/modules/Diagram.Analytics.js'
import { DiagramSwiftypeAutoComplete } from '@/modules/Diagram.SwiftType.AutoComplete.js'
import { DiagramExpandingSearch } from '@/modules/Diagram.ExpandingSearch.js'

// THESE IMPORTS I THINK ARE NO LONGER NEEDED
//import '@/modules/Diagram.Accordions.js'
//import '@/modules/Diagram.Analytics.js'
//import '@/modules/Diagram.Carousels.js'
//import '@/modules/Diagram.Cookies.js'
//import '@/modules/Diagram.Pagination.js'
//import '@/modules/Diagram.ScrollLock.js'
//import '@/modules/Diagram.SiteNavigation.js'
//import '@/modules/Diagram.ExpandingSearch.js'
//import '@/modules/Diagram.SmoothAnchors.js'
//import '@/modules/Diagram.Tables.js'
//import '@/modules/Diagram.Toggle.js'
//import '@/modules/Diagram.Tabs.js'
//import '@/modules/Diagram.ContentFit.js'
//import '@/modules/Diagram.Masonry.js'
//import '@/modules/Diagram.LocationFilter.js'
import '@/plugins/toddmotto.fluidvids.js'

DiagramHasJS();

if (typeof pageTracker == "undefined") {
    var pageTracker = {};
}

function customGAEvent(category, action, label) {
    try {
        pageTracker._trackEvent(category, action, label);
    } catch (e) {
        // console.log(e);
    }
}

// Items that can be processed before/after images/css complete
$(document).ready(async function ($) {
    await DiagramAnalytics();
    await DiagramSwiftypeAutoComplete();
    await DiagramExpandingSearch();
    fluidvids.init();

    if ($('.js-overflow-scroll').length) {
        const { Scrollbar } = await import('@/plugins/jquery-scrollbar.js');
        await Scrollbar();
    }

    //// focus on the search input when the search toggle (narrow) is tapped
    $('.js-narrow-search-toggle').on('click', function () {
        $('.js-header-search-input').focus();
    });

    if ($('.js-accordion, .js-panel').length) {
        const { DiagramAccordions } = await import('@/modules/Diagram.Accordions.js');
        await DiagramAccordions();
    }

    if ($('.js-tabs').length) {
        const { DiagramTabs } = await import('@/modules/Diagram.Tabs.js');
        await DiagramTabs();
    }

    if ($('.js-tabcordions').length) {
        const { DiagramTabcordions } = await import('@/modules/Diagram.Tabcordions.js');
        await DiagramTabcordions();
    }

    if ($('.aa-wrap').length) {
        const { DiagramAppendAround } = await import('@/modules/Diagram.AppendAround.js');
        await DiagramAppendAround();
    }

    if ($('.js-main-nav').length) {
        const { DiagramSiteNavigation } = await import('@/modules/Diagram.SiteNavigation.js');
        await DiagramSiteNavigation();
    }

    if ($('[data-content-fit-wrapper]').length) {
        const { DiagramContentFit } = await import('@/modules/Diagram.ContentFit.js');
        await DiagramContentFit();
    }

    if ($('.js-page-select').length) {
        const { DiagramPagination } = await import('@/modules/Diagram.Pagination.js');
        await DiagramPagination();
    }

    if ($('.js-carousel, .js-scrolling-hero, .js-synchronized-sliders, .js-listing-carousel').length) {
        const { DiagramCarousels } = await import('@/modules/Diagram.Carousels.js');
        await DiagramCarousels();
        import('youtube-background');
    }

    if ($('table, .scrolling-table').length) {
        const { DiagramTables } = await import('@/modules/Diagram.Tables.js');
        await DiagramTables();
    }

    if ($('.js-scroll-anchor').length) {
        const { DiagramSmoothAnchors } = await import('@/modules/Diagram.SmoothAnchors.js');
        await DiagramSmoothAnchors();
    }

    if ($('.gallery--masonry').length) {
        const { DiagramMasonry } = await import('@/modules/Diagram.Masonry.js');
        await DiagramMasonry();
    }

    if ($('.fancybox, .fancybox-media, .fancybox-modal, [data-fancybox]').length) {
        const { DiagramFancybox } = await import('@/modules/Diagram.Fancybox.js');
        await DiagramFancybox();
    }

    if ($('.eventbrite-block').length) {
        const { DiagramEventbrite } = await import('@/modules/Diagram.Eventbrite.js');
        await DiagramEventbrite();
    }

    if ($('[data-location-select]').length) {
        const { DiagramLocationPanel } = await import('@/modules/Diagram.LocationPanel.js');
        await DiagramLocationPanel();
    }
});

// This occurs after the page is done loading.
$(window).on("load", function () {
    if (typeof _gat != "undefined") {
        pageTracker = _gat._createTracker('UA-11858386-2');
    }

    $('[data-ga-track]').each(function () {
        var $this = $(this);
        if ($this.attr('data-ga-category') != undefined && $this.attr('data-ga-event') != undefined && $this.attr('data-ga-label') != undefined) {
            customGAEvent($this.attr('data-ga-category'), $this.attr('data-ga-event'), $this.attr('data-ga-label'));
        }
    });
});